

import {
  Box,
  debounce,
  Menu,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";

import { AppConfig } from "../../../../Config/app-config";

import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  TableFilter
} from "../../../../TableStyle";
import {
  TemplateListWrapper
} from "../Templates/TemplatesListingCSS";
import { BROADCAST_WHATSAPP_FORM_DATA } from "../Utils/Constants";
import { PageDiv } from "./Styles";

import { GridCloseIcon } from "@mui/x-data-grid";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { NewNativeSelect } from "../../../../AppComponents/Forms/Styles";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../../HOC/HocBackdropLoader";
import { broadcastActions } from "../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { dateRange } from "../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { resetError } from "../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.slice";
import {
  DeleteError,
  DeleteSuccess,
} from "../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import DetailsTable from "../../../MainDashboard/DeatilsTable/DetailsTable";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { DashboardActions } from "../../../../Store/Slices/DashboardRedux/dashboard.actions";
import ExportConfirmation from "../../../Leads/contactDetailedComponents/ExportConfirmation";
import LogsTable from "./LogsTable";

interface Props extends HocBackdropLoaderProps {
}
// interface Props { }

const WhatsappBroadcastHistoryLogs: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [whatsappTotalMessagesLogsData, setWhatsappTotalMessagesLogsData] = useState<any>()
  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const [pagination, setPagination] = useState<any>()
  const dashdateRange: any = useAppSelector(dateRange);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "",
    source: "",
    category: "",
    startDate: null,
    endDate: null,
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    totalPages: 0,
  });


  let { showAlert } = useNotification();
  const deleteErrorMessage = useAppSelector(DeleteError);
  const deleteSuccessMessage = useAppSelector(DeleteSuccess);
  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
  }, []);

  useEffect(() => {
    // getPreviousData();
    getLogs()
  }, [localValues, dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  // Hadnling Search Functionality
  const handleSelectChange = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
  }, 700);



  const navigate = useNavigate();
  const editBroadcastTemplate = (id: any, view?: boolean) => {
    if (view) {
      return navigate(`/broadcast/whatsapp/edit-broadcast/1/${id}?view=true`);
    }
    navigate(`/broadcast/whatsapp/edit-broadcast/4/${id}`);
  };
  useEffect(() => {
    if (deleteErrorMessage && deleteErrorMessage?.length > 0) {
      showAlert(deleteErrorMessage, "error");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
    if (deleteSuccessMessage && deleteSuccessMessage?.length > 0) {
      showAlert(deleteSuccessMessage, "success");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
  }, [deleteSuccessMessage, deleteErrorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSelectIconSource = localValues.source ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const handleSelectChangeWhatsapp = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
      currentPage: 0,
    });
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
      currentPage: 0,
    });
  };
  const getLogs = () => {
    // setLoading(true);
    setIsOpen(false)
    setWhatsappTotalMessagesLogsData({})
    let data = {
      "log_type": "detailed_logs",
      "start_date": moment(dashdateRange.startDate).format("YYYY-MM-DD"),
      "end_date": moment(dashdateRange.endDate).format("YYYY-MM-DD"),
      "limit": localValues?.itemsPerPage?.value,
      "offset": localValues?.currentPage,
      "search": localValues?.search,
      "status": localValues?.status,
      "source": localValues?.source
    }
    dispatch(
      broadcastActions.getWhatsappLogs(data)
    )
      .then((res: any) => {
        setPagination(res?.payload?.data?.data?.pagination)
        setWhatsappTotalMessagesLogsData(res?.payload?.data?.data)
        // setLoading(false);
      })
      .catch((error: any) => { });
  }
  const convertResponseToTableColumns = (data: any[]) => {
    let columns: any = {};
    data.forEach((item: any) => {
      columns[item?.key] = item?.label;
    });
    return columns
  }
  const restFilter = () => {
    setLocalValues({
      ...localValues,
      status: "",
      source: "",
    });
    setIsOpen(false)
  };


  const whatsappExportTemplateLogs = () => {
    const createPayload = () => {
      const rawPayload = {
        "start_date": moment(dashdateRange.startDate).format("YYYY-MM-DD") ? moment(dashdateRange.startDate).format("YYYY-MM-DD") : null,
        "end_date": moment(dashdateRange.endDate).format("YYYY-MM-DD") ?  moment(dashdateRange.endDate).format("YYYY-MM-DD") : null,
        "search": localValues?.search ? localValues?.search : null,
        "status": localValues?.status ? localValues?.status : null,
        "source": localValues?.source ? localValues?.source : null
      };
      // Filter out any fields that have null or undefined values
      return Object.fromEntries(Object.entries(rawPayload).filter(([_, value]) => value != null));
    };
    const payload = createPayload();
    setLoading(true)    
    dispatch(DashboardActions.getExportWhatsappLogsAction(payload)).then((res: any) => {
      setLoading(false);
      if (res?.error?.message === "Rejected") {
        showAlert(res?.error?.message || "Contact not exported", "error");
      } else {
        setSelectedComponent(true);
      }
    })
    .catch((error) => {
      showAlert("something went wrong", "error");
    })
    .finally(() => {
      setLoading(false);
    });
}


const closeModal = () => {
  setSelectedComponent(null);
};

  return (
    <>
      <TemplateListWrapper style={{ height: "inherit", overflow: "hidden" }}>
        {/* Top Bar */}
        {/* ==== Header ==== */}

        {/* ==== Filter ==== */}
        <PageDiv style={{ height: "calc(100vh - 228px)" }}>

          <AppForm
            initialValues={{ search: "" }}
            onSubmit={(values: any, submitProps: any) => { }}
            divStyle={{ width: "100%" }}
          >
            <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div className="filter-left">
                <Typography fontSize="13px" fontWeight={"400"} component="h6">
                  Sort By 
                </Typography>
                {/* <Box>
                  <AppRangeDatePicker
                    dateRange={dateRange}
                    reset={true}
                    setDateRange={setDateRange}
                    divStyle={{
                      padding: "0px",
                      maxWidth: "225px",
                      minWidth: "170px"
                    }}
                    dateFieldStyle={{
                      height: "28px",
                      "@media screen and (max-width: 1366px)": {
                        color: "red !important"
                      }
                    }}
                  />
                </Box> */}
                <Box>
                  <AppFormSearchField
                    name="search"
                    placeholder="Search"
                    defaultBorder={true}
                    divStyle={{
                      marginBottom: "0",
                    }}
                    clearCallback={(e: any) => clearCallback(e, "search")}
                    onChange={(e: any) => handleSelectChange(e, "search")}
                  />
                </Box>
                <Box>
                  <AppButton
                    variant="grey"
                    style={{ width: "100%" }}
                    id="basic-button"
                    aria-controls={isOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? "true" : undefined}
                    onClick={() => setIsOpen(true)}
                  >
                    <AppMaterialIcons iconName={"Sort"} />
                    <span style={{ marginLeft: "0.7rem" }}>Filter</span>
                  </AppButton>
                  <Menu
                    id="basic-menu"
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    anchorEl={document.getElementById('basic-button')}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <div className="setting-page-mainboxtopbar__right">
                      <MenuItem>
                        <p style={{ marginRight: "12px" }}>Status</p>
                        {/* Select 1 */}
                        <NewNativeSelect>
                          <Select
                            defaultValue={undefined}
                            name="status"
                            value={localValues?.status}
                            onChange={(e: any) => handleSelectChangeWhatsapp(e, "status")}
                            displayEmpty
                            className="new-select-main"
                          >
                            <MenuItem value="" disabled>
                              {" "}
                              Select an option
                            </MenuItem>
                            {/* {filtersValues?.status?.map(
                            (item: any, index: any) => ( */}
                            <MenuItem value={"sent"} key={1}>
                              {"Sent"}
                            </MenuItem>
                            <MenuItem value={"delivered"} key={2}>
                              {"Delivered"}
                            </MenuItem>
                            <MenuItem value={"seen"} key={3}>
                              {"Seen"}
                            </MenuItem>
                            <MenuItem value={"replied"} key={4}>
                              {"Replied"}
                            </MenuItem>
                            <MenuItem value={"failed"} key={5}>
                              {"Failed"}
                            </MenuItem>

                            {/* )
                          )} */}
                          </Select>
                          <div
                            className="clear-icon"
                            onClick={(e: any) => clearCallback(e, "status")}
                          >
                            {renderSelectIconStatus}
                          </div>
                        </NewNativeSelect>
                      </MenuItem>
                      <MenuItem>
                        <p style={{ marginRight: "10px" }}>Source</p>
                        {/* Select 2 */}
                        <NewNativeSelect>
                          <Select
                            defaultValue={undefined}
                            name="source"
                            value={localValues?.source}
                            onChange={(e: any) =>
                              handleSelectChangeWhatsapp(e, "source")
                            }
                            displayEmpty
                            className="new-select-main"
                          >
                            <MenuItem value="" disabled>
                              {" "}
                              Select an option
                            </MenuItem>
                            {/* {filtersValues?.sender_ids?.map(
                            (item: any, index: any) => ( */}
                            <MenuItem value={"automation"} key={1}>
                              {"Automation"}
                            </MenuItem>
                            <MenuItem value={"broadcast"} key={1}>
                              {"Broadcast"}
                            </MenuItem>
                            <MenuItem value={"contact"} key={1}>
                              {"Contact"}
                            </MenuItem>
                            <MenuItem value={"api"} key={1}>
                              {"Api"}
                            </MenuItem>
                            <MenuItem value={"inbox"} key={1}>
                              {"Inbox"}
                            </MenuItem>
                            <MenuItem value={"test"} key={1}>
                              {"Single Message"}
                            </MenuItem>
                            {/* )
                          )} */}
                          </Select>
                          <div
                            className="clear-icon"
                            onClick={(e: any) => clearCallback(e, "source")}
                          >
                            {renderSelectIconSource}
                          </div>
                        </NewNativeSelect>
                      </MenuItem>
                    </div>
                    <hr />
                    <div className="setting-page-mainboxtopbar__right">
                      <Box display="flex" sx={{ gap: "1rem" }}>
                        <AppButton
                          variant="outline"
                          style={{
                            minWidth: "95px", marginLeft: "34%",
                            marginTop: "12px",
                            marginBottom: "12px",
                            padding: "15px"
                          }}
                          onClick={() => {
                            restFilter();
                          }}
                        >
                          Reset
                        </AppButton>
                      </Box>
                    </div>
                  </Menu>
                </Box>
              </div>
              <div className="filter-right">

              {

whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"]?.length ?  <AppButton
style={{ width: "20px" }}
variant="outline"
onClick={whatsappExportTemplateLogs}
downloadFile
>
<FileUploadOutlinedIcon />
</AppButton> : null

}

              </div>
            </TableFilter>
          </AppForm>

          {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"] ?
            <LogsTable
              headers={whatsappTotalMessagesLogsData?.label_mapping ?? []}
              data={whatsappTotalMessagesLogsData?.logs} />
            : <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - ${335}px)`}
            />}

        </PageDiv>
      </TemplateListWrapper>
      {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"]?.length ? (
        <AppPaginationComponent
          totalCount={pagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={pagination?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Contacts"
        />
      ) : null}

<ExportConfirmation
                onClose={closeModal}
                selectedComponent={selectedComponent}
              />
    </>
  );
};

export default HocBackdropLoader(WhatsappBroadcastHistoryLogs);
// export default WhatsappBroadcastHistoryLogs;
